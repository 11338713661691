<template>
    <div class="CooperationNegotiate">
        <!-- <h1>合作洽谈</h1> -->
        <img src="https://img.youstarplanet.com/h5/CooperationNegotiate.png" alt="">
        <ul class="content">
            <li>
                <div>项目合作</div>
                <p>
                    邮箱：qianzhifeng@youstarplanet.com<br>
                    电话：18126208307<br>
                </p>
            </li>
            <li>
                <div>版权合作</div>
                <p>
                    邮箱：zhangtao@youstarplanet.com<br>
                    电话：18124111200<br>
                </p>
            </li>
            <li>
                <div>平台合作</div>
                <p>
                    邮箱：zhangtao@youstarplanet.com<br>
                    电话：18124111200<br>
                </p>
            </li>
            <li>
                <div>投融资合作</div>
                <p>
                    邮箱：wenzhang@youstarplanet.com<br>
                    电话：18124110188<br>
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
export default{
    data() {
        return {}
    },
    async mounted() {
        //页面跳转默认顶部
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.CooperationNegotiate{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .content{
        padding: 20px 14px 37px;
        li{
            background-color: #F8F8F8;
            margin-top: 10px;
            padding: 18px 24px;
            &:nth-child(1){
                margin-top: 0;
            }
            div{
                font-size: 16px;
                font-weight: bold;
                color: #222222;
                margin-bottom: 13px;
            }
            &>p{
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
            }
        }
    }
}
</style>